@import url('https://fonts.googleapis.com/css2?family=GT+Super+Display:wght@300;400&display=swap');
/* General styles for the header */
header {
  position: sticky;
  top: 0;
  background-color: #3C3B48;
  color: white;
  border-bottom: 1px solid #B49B57;
  z-index: 1000;
  width: 100%;
}

/* Flex container for the logo and nav content */
header .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px; /* Add padding for better spacing */
  max-width: 1200px;
  margin: 0 auto;
}

/* Logo styles */
header .logo img {
  height: 100px;
  width: auto;
}

/* Navigation for desktop (visible on large screens) */
.desktop-nav ul {
  list-style: none;
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
}

.desktop-nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-family: 'GT Super Display', serif !important;
}

/* Move the hamburger button to the right */
.menu-toggle {
  display: none;
}

/* Drawer Navigation (for mobile) */
.drawer {
  position: fixed;
  top: 0;
  right: -250px; /* Start hidden */
  height: 100%;
  width: 250px;
  background-color: #3C3B48;
  color: white;
  padding-top: 60px;
  transition: right 0.3s ease;
  z-index: 1001; /* Above everything */
}

.drawer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.drawer ul li {
  padding: 10px 20px;
}

.drawer ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.drawer.open {
  right: 0; /* Slide in the drawer */
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Below drawer, above header */
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  /* Keep logo and menu-toggle on the same line */
  header .content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  /* Hide desktop navigation on mobile */
  .desktop-nav {
    display: none;
  }

  /* Show the menu toggle button */
  .menu-toggle {
    display: block;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    margin-left: auto; /* Move hamburger button to the right */
  }

  /* Stack navigation items in the drawer (mobile) */
  header nav ul {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-top: 10px;
  }

  /* Adjust logo size on small screens */
  header .logo img {
    height: 80px; /* Smaller logo for smaller screens */
  }
}

/* Responsive styles for small mobile screens */
@media screen and (max-width: 480px) {
  header img {
    height: 60px; /* Even smaller logo */
  }
}
