.about-us {
    background-color: #3C3B48;
    text-align: center;
    color: white;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ffffff;
  }
  
  .about-us a{
    
    color: #B49B57;
  }
  .about-title {
    padding-top: 20px;
    padding-bottom: 80px;
    color: #B49B57;
    font-size: 36px; /* Default font size */
  }
  
  .about-us img {
    width: 100%;
    max-width: 500px; /* Restrict maximum width of images */
    height: auto; /* Ensure the aspect ratio is maintained */
    object-fit: cover;
    object-position: 50% 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.19);
  }
  
  /* Button styles */
  .btn-about {
    color: white;
    padding: 10px 40px;
    background-color: #B49B57;
    border: 1px solid #ffffff;
    border-radius: 0%;
    font-size: 16px; /* Button font size */
  }
  
  .btn-about:hover {
    background-color: #ffffff;
    color: black;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    /* For tablet screens */
    .about-title {
      font-size: 28px; /* Smaller title for tablet */
      padding-bottom: 40px; /* Less padding */
    }
  
    .about-us img {
      width: 100%; /* Make images take full width */
      max-width: 400px; /* Restrict maximum width */
    }
  
    .btn-about {
      font-size: 14px; /* Smaller button font size */
      padding: 8px 30px; /* Smaller padding for buttons */
    }
  
    .about-us .row {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center content on smaller screens */
      padding: 0;
    }
  
    .about-us .row .col-md-6 {
      width: 100%; /* Make columns full width */
      margin-bottom: 20px; /* Add space between columns */
    }
  }
  
  @media screen and (max-width: 480px) {
    /* For mobile screens */
    .about-title {
      font-size: 24px; /* Smaller title for mobile */
      padding-bottom: 30px;
    }
  
    .about-us img {
      width: 100%; /* Full width for images */
      max-width: 300px; /* Reduce maximum width on mobile */
    }
  
    .btn-about {
      font-size: 12px; /* Even smaller font size for mobile */
      padding: 6px 20px; /* Smaller padding for buttons */
    }
  
    .about-us .row {
      display: flex;
      flex-direction: column;
      align-items: center; /* Stack columns vertically */
    }
  
    .about-us .row .col-md-6 {
      width: 100%; /* Full width for columns */
      margin-bottom: 15px;
    }
  }
  