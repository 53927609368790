/* Contact Page Styles */
.contact-page {
  background: linear-gradient(to bottom, #0f2027, #203a43, #2c5364);
  color: white;
  padding: 100px 0;
  min-height: 60vh;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Header */
.contact-page h1 {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

/* Contact details container */
.contact-details {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  flex-wrap: wrap;
}

/* Contact card styling */
.contact-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 30px;
  width: 300px;
  margin: 10px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}

/* Card headers */
.contact-card h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

/* Links */
.contact-page a {
  color: #00ffff;
  text-decoration: none;
}

.contact-page a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-details {
    flex-direction: column;
    align-items: center;
  }
}

/* Terms Page Styles */
.terms-page {
  background-color: #f5f5f5;
  color: #333;
  padding: 80px 0;
  min-height: 100vh;
}

.terms-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header */
.terms-page h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

/* Terms Content */
.terms-content {
  text-align: left;
  line-height: 1.8;
  margin-top: 20px;
}

.terms-content p {
  margin-bottom: 15px;
}

/* Responsive design */
@media (max-width: 768px) {
  .terms-container {
    padding: 15px;
  }
  
  .terms-page h1 {
    font-size: 2em;
  }
}

/* Privacy Page Styles */
.privacy-page {
  background-color: #f8f9fa;
  color: #333;
  padding: 80px 0;
  min-height: 100vh;
}

.privacy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header */
.privacy-page h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

/* Privacy Content */
.privacy-content {
  text-align: left;
  line-height: 1.8;
  margin-top: 20px;
}

.privacy-content p {
  margin-bottom: 15px;
}

.privacy-content a {
  color: #007bff;
  text-decoration: none;
}

.privacy-content a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {
  .privacy-container {
    padding: 15px;
  }
  
  .privacy-page h1 {
    font-size: 2em;
  }
}

/* Cookie Page Styles */
.cookie-page {
  background-color: #f4f6f8;
  color: #333;
  padding: 80px 0;
  min-height: 100vh;
}

.cookie-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header */
.cookie-page h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

/* Cookie Content */
.cookie-content {
  text-align: left;
  line-height: 1.8;
  margin-top: 20px;
}

.cookie-content p {
  margin-bottom: 15px;
}

/* List Styles */
.cookie-content ul {
  margin-left: 20px;
}

.cookie-content ul li {
  margin-bottom: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .cookie-container {
    padding: 15px;
  }
  
  .cookie-page h1 {
    font-size: 2em;
  }
}
