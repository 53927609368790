.slider-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 46vh;
  }
  
  .slider-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    min-width: 100%;  /* Ensure each slide is full width */
    height: 46vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slide-content {
    color: #fff;
    text-align: right;
    align-content: center;
    padding: 2rem;
    border-radius: 8px;
    max-width: 50%;
  }
  
  .prev, .next {
    margin-left: 40px;
    margin-right: 40px;
    transition: transform 0.3s ease;
    position: absolute; /* Ensure positioning is absolute for manual positioning */
    top: 50%;
    cursor: pointer;
    background-color: #ffffff00;
    color: #B49B57;
    border: none;
    padding: 10px 20px;
    font-size: 24px;
  }  

  .prev {
    left: 10px;
    transform-origin: center left; /* Anchor the transformation to the left */
  }
  
  .next {
    right: 10px;
    transform-origin: center right; /* Anchor the transformation to the right */
  }
  
  .prev:hover, .next:hover {
    transform: scale(1.5); /* Scales up the icon, anchored from transform-origin */
  }
  
  button {
    color: white;
    padding: 10px 40px;
    transition: all 0.2s ease, visibility 0s;
    background: #1E1E24;
    border-radius: 50px;
    border: 2px solid #B49B57;
  }

  .fa-icon {
    margin-left: 10px;
    transition: transform 0.3s ease;
  }
  
  button:hover .fa-icon {
    transform: scale(1.5); /* Scales up the icon */
    margin-left: 15px;
  }

  .slide button {
    position: absolute;
    bottom: 10px;
    margin: auto;
    inline-size: max-content;
    
  }
  @media screen and (max-width: 768px) {
    .slide-content {
      max-width: 100%;
    }
    .prev{
      left: 0px;
      padding: 0px;
    }
    
     .next {
      padding: 0px;
      right: 0px;
    }
    h2{
      font-size: 20px;
      text-align: left;
    }
    .slide-content button{
      padding: 5px 10px;
    }
  }