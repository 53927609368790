footer {
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #3C3B48;
  color: rgb(201, 200, 209);
  font-size: 0.9rem;
}

footer .container {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
}

footer .container a {
  color: #B49B57;
  font-weight: 400;
}

footer p {
  margin: 0;
  padding-top: 5px;
  font-weight: 600;
}

footer .container::after {
  content: '';
  display: block;
  clear: both;
  width: 100%;
  height: 1px;
  background-color: rgb(180, 155, 87);
  margin-top: 20px;
}

footer .links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

footer .links a {
  color: white;
  text-decoration: underline;
  padding: 0.5rem;
  font-size: 16px;
}

footer .links a:hover {
  color: rgb(180, 155, 87);
}

footer ul {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  gap: 10px;
}

footer ul li a {
  text-decoration: none;
}

.footer-bottom {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.footer-copyright {
  text-align: center;
  font-size: 14px;
}

footer img {
  height: 35px;
  padding: 5px;
  width: auto;
}

footer .footerlogo {
  height: 120px;
  width: auto;
}
footer .social-footer{
  text-align: right;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  footer .row {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  footer .col-md-8, footer .col-md-4 {
    width: 100%;
    text-align: center;
  }

  footer ul {
    justify-content: center; /* Center social icons on tablet */
  }

  .footer-bottom {
    padding-top: 50px;
  }

  footer .footerlogo {
    height: 150px; /* Resize logo for tablet */
  }
  footer .social-footer{
    text-align: center;
    padding-top: 50px;
  }
  footer .social-footer ul{
    padding: 0px;
  }


}

@media screen and (max-width: 480px) {
  footer .container {
    padding: 0 20px; /* Add padding on mobile */
  }

  footer ul {
    gap: 5px;
    justify-content: center; /* Center social icons on mobile */
  }

  footer img {
    height: 30px; /* Resize social icons for mobile */
  }

  footer .footerlogo {
    height: 150px; /* Resize logo for tablet */
  }

  .footer-bottom {
    padding-top: 30px;
  }
}
