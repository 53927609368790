.sponsors {
    background-color: #fafafa;
    padding-top: 55px;
    padding-bottom: 55px;
    text-align: center;
  }
  
  .sponsors h2 {
    font-size: 32px;
    color: #333;
    margin-bottom: 40px;
  }
  
  .sponsors img {
    height: 150px;
    width: auto;
    transition: opacity 0.3s ease; /* Smooth hover transition */
  }
  
  .sponsors a:hover {
    opacity: 0.7;
  }
  .block-sponsors{
    display: inline-grid;
    justify-content: center;
    align-items: center;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .sponsors h2 {
      font-size: 28px; /* Smaller font size for tablets */
    }
  
    .sponsors .row {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center content on smaller screens */
    }
  
    .sponsors .col-md-6 {
      width: 100%; /* Full width for each sponsor */
      margin-bottom: 20px; /* Add space between the sponsor images */
    }
  
    .sponsors img {
      height: 50px; /* Scale down the images */
    }
  }
  
  @media screen and (max-width: 480px) {
    .sponsors h2 {
      font-size: 24px; /* Smaller heading for mobile */
    }
  
    .sponsors img {
      height: 50px; /* Even smaller images for mobile */
    }
  
    .sponsors .col-md-6 {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  