/* Form container styles */
.form-container {
    width: 70%;
    margin: 60px auto;
    background-color: #3C3B48;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* To position the button at the bottom */
    color: white;

  }
  
  /* Progress bar */
  .progress-bar {
    width: 100%;
    height: 10px;
    background: #ddd;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-bar div {
    height: 100%;
    background: #B49B57;
    transition: width 0.3s ease-in-out;
  }
  
  /* Label and input styling */
  label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }
  
  input,
  select,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    color: white;
    border: 1px solid #B49B57;
    box-sizing: border-box;
    background-color: #3C3B48;
  }
  
 /* Container for buttons positioned at the bottom and right */
/* Container for buttons positioned at the bottom */
.button-container {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between; /* Place prev on the left and next on the right */
    gap: 10px;
}

/* Style for buttons */
.button-container button {
    width: auto;
    padding: 10px 20px;
    background-color: #B49B57;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.button-container button:hover {
    background-color: #B49B57;
}

.form-container .formprev{
    position: absolute;
    left: 25px;
    bottom: 25px;
}
  
.form-container .formnext{
    position: absolute;
    right: 25px;
    bottom: 25px;
}
  /* Background for the entire page */
  body {
    background-color: #e0e0e0;
   
  }
  .footer-banner img {
    width: 100%;
    height: auto;
  }
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .form-container {
      width: 95%;
    }
  
    .button-container button {
      width: 100%;
      margin-bottom: 10px;
    }
    .footer-banner img {
      height: 200px; /* Fixed height for mobile */
      width: 100%;
      object-fit: cover; /* Ensures the image is zoomed/cropped to fit the container */
    }
  }

 