.social {
    display: grid;
    place-content: center;
    background-color: #242323;
    padding-top: 55px;
    padding-bottom: 55px;
    text-align: center; /* Center content for small screens */
    text-align-last: center;
  }
  
  .social .content {
    display: grid;
    max-width: 1080px;
    width: 100%; /* Make the content responsive */
    text-align: center;
    margin: 0 auto; /* Center content horizontally */
  }
  
  .social .content h2 {
    font-size: 36px;
    margin-bottom: 2rem;
    color: rgb(180, 155, 87);
  }
  
  .social .content h3 {
    font-size: 24px;
    margin-bottom: 2rem;
    color: rgb(255, 255, 255);
  }
  
  .social .social-icons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Allow icons to wrap on smaller screens */
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  table td {
    text-align: center;
    padding: 10px;
  }
  
  table img {
    width: 80px;
    height: auto;
    transition: transform 0.3s ease; /* Add hover effect */
  }
  
  table img:hover {
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .social .content h2 {
      font-size: 28px; /* Scale down heading for tablet */
    }
  
    .social .content h3 {
      font-size: 20px; /* Scale down subheading for tablet */
    }
  
    table img {
      width: 70px; /* Smaller icons for tablet */
    }
  
    .social .social-icons {
      flex-direction: column; /* Stack icons vertically on tablet */
    }
  
    table td {
      padding: 8px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .social .content h2 {
      font-size: 24px; /* Smaller heading for mobile */
    }
  
    .social .content h3 {
      font-size: 18px; /* Smaller subheading for mobile */
    }
  
    table img {
      width: 60px; /* Smaller icons for mobile */
    }
  
    .social .social-icons {
      flex-direction: column; /* Stack icons vertically on mobile */
    }
  
    table td {
      padding: 5px; /* Less padding on mobile */
    }
  }
  