@import url('https://fonts.googleapis.com/css2?family=Commuter+Sans:wght@400;700&display=swap');


body {
  margin: 0;
    font-family: 'Commuter Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h2 {
  font-weight: 300 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
